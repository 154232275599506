import { DateTime } from './common'

export type CorrectionMarker = {
  pageNumber: number
  x: number
  y: number
  id: string
}

export type PronunciationItem = {
  text: string
  lemma: string
  page: number
  comment?: string
  language?: string
  count?: number
  // Internal state only, not in API
  id: string
  selected?: boolean
  textIndex?: number // page + word index in page
}

export type SearchItem = {
  text: string
  id: string
}

export type GuideSet = {
  type: 'DRAFT' | 'LIVE'
  bookDetails: string
  generalInstructions: string
  items: PronunciationItem[]
  lastUpdated: string
  correctionsMarkers: CorrectionMarker[]
}

export type PronunciationsFetchActionPayload = {
  organizationId: string
  isbn: string
}

export type PronunciationResponse = {
  guideSets: GuideSet[]
}

export type PronunciationPublishResponse = {
  guideSet: GuideSet
}

export type PronunciationsUpdateActionPayload = {
  organizationId: string
  isbn: string
  lastUpdated: DateTime
  payload: Partial<Pick<GuideSet, 'type' | 'bookDetails' | 'generalInstructions' | 'items' | 'correctionsMarkers'>>
}

export type PronunciationUpdateActionResponse = GuideSet

export type PronunciationsAddItemActionPayload = {
  organizationId: string
  isbn: string
  payload: Pick<PronunciationItem, 'comment' | 'language' | 'page' | 'text' | 'lemma' | 'count' | 'textIndex'>
  wordPos: number
}

export type PronunciationsEditItemActionPayload = {
  id: string
  payload: Partial<PronunciationItem>
}

export type PronunciationsDeleteItemActionPayload = {
  id: PronunciationItem['id']
}

export type PronunciationsSelectItemsActionPayload = {
  itemIds: PronunciationItem['id'][]
  isSelected: boolean
}

export type PronunciationsPublishActionPayload = {
  organizationId: string
  isbn: string
}

export type TTSGender = 'MALE' | 'FEMALE'

export type TTSLanguage = {
  languageCode: string
  genders: TTSGender[]
  count: number // Internal state only, calculated total usage count for language
}

export type TTSSettings = {
  languages: TTSLanguage[]
}

export type TTSResponse = TTSSettings

export type TTSReaderOptions = {
  gender: TTSGender
  speakingRate: number
}

export type TTSAudioPayload = {
  text: string
  language: string
  speakingRate: number
  gender: TTSGender
  // Only without authentication:
  linkId?: string
  linkSignature?: string
}

export type PronunciationsSetTxtContentActionPayload = {
  textContent: string[]
}

export type PdfViewerState = {
  currentPage: number
  pageCount: number
}

export type PronunciationCredentials = {
  credentialId: string
  editable: boolean
}

export type PronunciationsSetCredentialsActionPayload = {
  id: string
  editable: boolean
}

export enum EditedField {
  comment = 'comment',
  name = 'name',
  added = 'added',
  deleted = 'deleted',
  lang = 'language',
}

export interface EditedItemPayload {
  items: PronunciationItem[]
  fieldName: EditedField
}

export enum SnapshotKey {
  GUIDE_SET_HISTORY = 'GUIDE_SET_HISTORY',
}
